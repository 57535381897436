import React from 'react'
import { Link } from 'gatsby'
import { Button } from '@farewill/ui'
import { ButtonProps } from '@farewill/ui/components/Button/types'

import ProductLink from 'components/ProductLink'
import { isInternalURL } from 'lib/url/isInternalURL'
import { filterByKey } from 'lib/object/helpers'
import { TRACKING_ATTRIBUTE_PREFIX } from 'lib/tracking/constants'

export type LinkButtonProps = {
  to: string
  children: string
  productLink?: boolean
  buttonTag?: React.FunctionComponent<React.PropsWithChildren<ButtonProps>>
  wide?: boolean
  stretch?: boolean
  preselectedService?: 'online' | 'telephone'
  partnerCampaignReferral?: boolean
  telephoneWillsEnabled?: boolean
  [key: `${typeof TRACKING_ATTRIBUTE_PREFIX}-${string}`]: string
}

const LinkButton = ({
  children,
  to,
  productLink = false,
  wide = true,
  stretch = false,
  preselectedService,
  partnerCampaignReferral = false,
  telephoneWillsEnabled = false,
  buttonTag: ButtonTag = Button.Primary,
  ...props
}: LinkButtonProps): React.ReactElement => {
  const isInternalLink = isInternalURL(to)
  const internalTag = productLink ? ProductLink : Link
  const tag = isInternalLink ? internalTag : 'a'
  const trackingProps = filterByKey(props, (key) =>
    key.startsWith(`${TRACKING_ATTRIBUTE_PREFIX}-`)
  )

  return (
    <ButtonTag
      tag={tag}
      to={to}
      href={to}
      stretch={stretch}
      wide={wide}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(productLink && {
        preselectedService,
        partnerCampaignReferral,
        telephoneWillsEnabled,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...trackingProps}
    >
      {children}
    </ButtonTag>
  )
}

export default LinkButton
