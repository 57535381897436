import React from 'react'
import { Grid, H, P, Image } from '@farewill/ui'

import {
  FF_TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_ENABLED,
  TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE_IN_POUNDS,
} from 'config'

const TelephoneWillsWhatsIncluded = (): React.ReactElement => (
  <Grid container>
    <Grid.Item spanFromM={7}>
      <H size="L" decorative>
        What’s included with Farewill?
      </H>
      <P>
        You can share out your estate, leave gifts and messages, appoint
        guardians, executors and more. And by writing your will on the phone, we
        can support you to include bespoke clauses to cover your specific
        situation.
      </P>
      {FF_TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_ENABLED && (
        <P>
          You can also add unlimited trusts and business assets to your will at
          a fixed additional cost of £
          {TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE_IN_POUNDS}.
        </P>
      )}
    </Grid.Item>
    <Grid.Item spanFromM={5} centered>
      <Image
        path="illustrations/experts-checking-couples"
        width={325}
        stretch
      />
    </Grid.Item>
  </Grid>
)

export default TelephoneWillsWhatsIncluded
