import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { P, Wrapper } from '@farewill/ui'
import { FONT, COLOR, GTR, BORDER_RADIUS } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

export const ErrorMessage = styled(P)`
  font-size: ${FONT.SIZE.XS};
  color: ${COLOR.STATE.ERROR};
  margin-top: ${GTR.XXS};
`

export const FormLabel = styled.label`
  color: ${COLOR.GREY.DARK};
  font-size: ${FONT.SIZE.M};
  margin-bottom: ${GTR.XS};
`

export const TextInput = styled.input`
  width: 100%;
  background-color: ${COLOR.WHITE};
  padding: ${GTR.M};
  border-radius: ${BORDER_RADIUS.S};
  border: 2px solid ${COLOR.GREY.LIGHT};
  outline: 0;
  color: ${COLOR.BLACK};
  transition: border-color 0.2s ease-in-out;
  line-height: 1.3;

  &:focus {
    border-color: ${COLOR.GREY.MEDIUM};
  }

  ${screenMax.s`
    padding: ${GTR.S};
  `}

  ${(props) =>
    props.error &&
    css`
      border: 1px solid ${COLOR.STATE.ERROR};
      padding: ${parseInt(GTR.M, 10) + 1}px;

      ${screenMax.s`
        padding: ${parseInt(GTR.S, 10) + 1}px;
      `}
    `}
`

const Input = ({ error, label, handleChange, name, type, value }) => (
  <Wrapper>
    <FormLabel htmlFor={`input-${name}`}>{label}</FormLabel>
    <TextInput
      id={`input-${name}`}
      name={name}
      value={value}
      type={type}
      onChange={handleChange}
      error={error}
    />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </Wrapper>
)

Input.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
}

Input.defaultProps = {
  error: undefined,
  type: 'text',
}

export default Input
