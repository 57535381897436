import React, { ReactElement, createRef } from 'react'
import styled from 'styled-components'
import { H, P } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR } from '@farewill/ui/tokens'

import { BUSINESS_UNIT_ID, WIDGET_TEMPLATE_ID } from 'lib/trustpilot/constants'

import PreventOrphan from 'components/PreventOrphan'
import LoadTrustpilotWidget from '../LoadTrustpilotWidget'

const StyledTrustBoxWrapper = styled.div`
  --trustbox-margin-offset: -20px;

  margin: 0 var(--trustbox-margin-offset);
  width: calc(100% + (-2 * var(--trustbox-margin-offset)));

  ${screenMin.xl`
    --trustbox-margin-offset: -50px;
  `}
`

type TrustpilotReviewsSectionProps = {
  tag?: string
  showTitle?: boolean
  title?: string
  footnote?: string
  widget?: keyof typeof WIDGET_TEMPLATE_ID
}

const TrustpilotReviewsSection = ({
  tag,
  showTitle,
  title = 'Voted the nation’s favourite will writing service',
  footnote,
  widget = 'SLIDER',
}: TrustpilotReviewsSectionProps): ReactElement | null => {
  const trustboxElement = createRef<HTMLDivElement>()

  return (
    <LoadTrustpilotWidget widget={trustboxElement}>
      {showTitle && (
        <H size="L" decorative margin={[0, 0, 'L']} centeredFromM>
          <PreventOrphan>{title}</PreventOrphan>
        </H>
      )}
      <StyledTrustBoxWrapper>
        <div
          data-locale="en-GB"
          data-template-id={WIDGET_TEMPLATE_ID[widget]}
          data-businessunit-id={BUSINESS_UNIT_ID}
          data-style-height="240px"
          data-style-width="100%"
          data-theme="light"
          data-stars="4,5"
          data-schema-type="Organization"
          data-tags={tag}
          ref={trustboxElement}
        />
      </StyledTrustBoxWrapper>
      {footnote && (
        <P
          margin={['XXS', 'auto', 0]}
          maxWidth={500}
          size="S"
          color={COLOR.BLACK}
          centeredFromM
        >
          <PreventOrphan>{footnote}</PreventOrphan>
        </P>
      )}
    </LoadTrustpilotWidget>
  )
}

export default TrustpilotReviewsSection
