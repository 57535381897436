import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Wrapper, Grid, H, Image, Divider } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { TELEPHONE_WILLS } from 'lib/products/constants'
import { trackFacebookEvent } from 'lib/tracking/index'
import { FACEBOOK_EVENTS } from 'lib/tracking/constants'
import { TELEPHONE_WILLS_CALLBACK_HOURS_GUARANTEE } from 'config'
import CallbackForm from 'components/CallbackForm'
import TrustpilotReviews from 'components/TrustpilotWidget/TrustpilotReviews'

const TelephoneWillsContact = ({ heroTitle, heroSubtitle }) => {
  useEffect(() => {
    trackFacebookEvent(FACEBOOK_EVENTS.ENTER_USER_DETAILS_VIEW)
  }, [])

  return (
    <Wrapper background={COLOR.WHITE}>
      <Grid container>
        <Grid.Item spanFromL={6}>
          <H tag="h1" size="XL" decorative>
            {heroTitle}
          </H>

          <Wrapper margin={[0, 0, 'XL']}>
            <ReactMarkdown escapeHtml>
              {heroSubtitle?.heroSubtitle}
            </ReactMarkdown>
          </Wrapper>

          <CallbackForm
            product={TELEPHONE_WILLS}
            background={COLOR.BACKGROUND.FOG}
          />
        </Grid.Item>

        <Grid.Item spanFromL={6} style={{ justifySelf: 'center' }}>
          <Image
            path="illustrations/cropped/blob-with-telephone"
            width={180}
            widthFromL={400}
            stretch
          />
        </Grid.Item>
      </Grid>

      <Divider container />

      <Wrapper container containerPaddingBottom={0}>
        <TrustpilotReviews showTitle />
      </Wrapper>
    </Wrapper>
  )
}

TelephoneWillsContact.propTypes = {
  heroTitle: PropTypes.string,
  heroSubtitle: PropTypes.shape({
    heroSubtitle: PropTypes.shape({ heroSubtitle: PropTypes.string }),
  }),
}

TelephoneWillsContact.defaultProps = {
  heroTitle: 'Let’s get your will sorted',
  heroSubtitle: {
    heroSubtitle: `Leave your name and number below and someone will be in touch within ${TELEPHONE_WILLS_CALLBACK_HOURS_GUARANTEE} hours to help get your will sorted.`,
  },
}

export default TelephoneWillsContact
