import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Wrapper, H, P, Button } from '@farewill/ui'
import { COLOR, GTR, BORDER_RADIUS } from '@farewill/ui/tokens'
import axios from 'axios'

import { trackEvent, trackConversion, trackFacebookEvent } from 'lib/tracking'
import { getSavedTrackingParams } from 'lib/tracking/trackingParams'
import { LIVE_PRODUCTS, PRODUCTS_MAP } from 'lib/products/constants'
import { PRODUCT_SUPPORT_MAP } from 'lib/time/constants'
import { formattedOpenHours, formattedOpenDays } from 'lib/time/formatting'
import {
  GOOGLE_ADS_CONVERSION_LABELS,
  FACEBOOK_EVENTS,
} from 'lib/tracking/constants'
import { FAREWILL_BACKSTAGE_URL } from 'config'
import Specialists from 'components/Specialists'
import TelephoneNumber from 'components/TelephoneNumber'

import DefaultLayoutContext from 'layouts/context'
import Input from './Input'

// Simple regex for phone numbers
// Allows 9-15 numbers, separated anywhere by dashes or spaces
// Can be prepended by a plus sign
const phoneNumberValidator = /^\+?(\d[\s-]?){9,15}$/

const StyledFormWrapper = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.L} ${GTR.M};
  border: 1px solid ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER_RADIUS.M};
  position: relative;

  ${({ $background }) => $background && `background-color: ${$background}`}
`

const StyledHeader = styled(H)`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;

  > *:nth-child(1) {
    margin: 0 ${GTR.S} ${GTR.M} 0;
  }

  > *:nth-child(2) {
    margin: -${GTR.XS} 0 ${GTR.S};
  }
`
class CallbackForm extends Component {
  state = {
    email: this.props.initialValues?.email || '',
    name: this.props.initialValues?.name || '',
    phoneNumber: this.props.initialValues?.phoneNumber || '',
    errors: {},
    error: false,
    loading: false,
  }

  handleChange = ({ target: { type, name, checked, value } }) => {
    const newValue = type === 'checkbox' ? checked : value
    const isEmpty = newValue.length === 0
    const { errors: prevErrors } = this.state
    const errors = Object.assign(prevErrors, { [name]: isEmpty })

    this.setState({ [name]: newValue, errors })
  }

  submit = async (e) => {
    e.preventDefault()

    const errors = this.validate()
    const hasErrors = Object.values(errors).some((error) => error)
    if (hasErrors) return this.setState({ errors })

    this.setState({ loading: true })

    const { product } = this.props
    const { name, email, phoneNumber } = this.state

    const trackingParameters = getSavedTrackingParams()

    const productData = PRODUCTS_MAP[product]

    const data = {
      type: 'call-requests',
      attributes: {
        name,
        email,
        phoneNumber,
        interestedInProducts: [productData.backstageEnum],
        trackingParameters,
      },
    }

    let response

    if (!Object.values(errors).includes(true)) {
      try {
        response = await axios.post(
          `${FAREWILL_BACKSTAGE_URL}/api/call-requests`,
          { data }
        )
        this.setState({ submitted: true })
        this.setState({ loading: false })
      } catch (error) {
        if (
          error.response?.data.errors.some(
            (err) => err?.source?.pointer === 'data.attributes.phoneNumber'
          )
        ) {
          this.setState((prevState) => ({
            ...prevState,
            errors: { ...prevState.errors, phoneNumber: true },
          }))
          this.setState({ loading: false })
        } else {
          this.setState({ error: true })
        }
      }

      if (
        !this.state.error &&
        !Object.values(this.state.errors).includes(true)
      ) {
        trackEvent(`Request callback (${productData.title})`, 'submit')
        trackConversion(GOOGLE_ADS_CONVERSION_LABELS.CALLBACK[product])
        trackFacebookEvent(FACEBOOK_EVENTS.LEAD)
        trackFacebookEvent(FACEBOOK_EVENTS.TELEPHONE_WILL_CALLBACK)
      }
    }

    return response
  }

  validate = () => {
    const { name, phoneNumber } = this.state

    return {
      name: name.length === 0,
      phoneNumber: !phoneNumber.match(phoneNumberValidator),
    }
  }

  render() {
    const { product, background, compact, showSpecialists, title, buttonText } =
      this.props
    const { email, loading, error, errors, name, phoneNumber, submitted } =
      this.state

    const showForm = !submitted && !error
    const showGuidance = !error
    const supportPhoneNumber = PRODUCTS_MAP[product].telephoneNumber
    const supportDaysOpenCount = PRODUCT_SUPPORT_MAP[product].daysOfWeek.length

    return (
      <StyledFormWrapper $background={background}>
        {title && (
          <StyledHeader size="M" margin={0}>
            <span>{title}</span>
            {showSpecialists && (
              <Specialists
                group={product}
                extraSmall
                outlineColor={background || COLOR.WHITE}
              />
            )}
          </StyledHeader>
        )}

        {error && (
          <>
            <H size="XS">Something went wrong</H>
            <P>It looks like something went wrong when submitting the form.</P>
            <P>
              We would still love to talk to you - give us a ring on{' '}
              <TelephoneNumber
                underline
                telephoneNumber={supportPhoneNumber}
                showIcon={false}
                strong={false}
                bold
              />
              .
            </P>
          </>
        )}
        {showForm && (
          <form onSubmit={this.submit}>
            <Grid gap={['M', 'S']} gapFromM="M" margin={[0, 0, 'M']}>
              <Grid.Item spanFromM={compact ? 6 : 12}>
                <Input
                  name="name"
                  value={name}
                  label="Your name"
                  type="text"
                  handleChange={this.handleChange}
                  error={errors.name && 'Your name is required'}
                />
              </Grid.Item>
              <Grid.Item spanFromM={compact ? 6 : 12}>
                <Input
                  name="phoneNumber"
                  value={phoneNumber}
                  label="Phone number"
                  type="tel"
                  pattern=""
                  handleChange={this.handleChange}
                  error={
                    errors.phoneNumber && 'Please enter a valid phone number'
                  }
                />
              </Grid.Item>
              <Grid.Item>
                <Input
                  name="email"
                  value={email}
                  label="Email (in case we can’t reach you by phone)"
                  type="email"
                  handleChange={this.handleChange}
                />
              </Grid.Item>
              <Grid.Item>
                <Button.Primary loading={loading} disabled={loading} stretch>
                  {buttonText}
                </Button.Primary>
              </Grid.Item>
            </Grid>
          </form>
        )}
        {submitted && (
          <H size="XS">
            Thanks for your callback request. We’ll be in touch soon.
          </H>
        )}
        {showGuidance && (
          <>
            <P>
              We’re open {formattedOpenHours(product)},{' '}
              {supportDaysOpenCount === 7
                ? '7 days a week'
                : formattedOpenDays(product)}
              .
              <br />
              We’ll call you back as soon as possible during working hours.
            </P>
          </>
        )}
      </StyledFormWrapper>
    )
  }
}

CallbackForm.contextType = DefaultLayoutContext

CallbackForm.propTypes = {
  product: PropTypes.oneOf(LIVE_PRODUCTS).isRequired,
  background: PropTypes.string,
  compact: PropTypes.bool,
  showSpecialists: PropTypes.bool,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
}
CallbackForm.defaultProps = {
  background: null,
  compact: false,
  showSpecialists: false,
  title: 'Request a callback',
  buttonText: 'Submit request',
  initialValues: {},
}

export default CallbackForm
